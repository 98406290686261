@import "../../styles/variables.scss";

#all {
  margin: 10px;
  background-color: $background_color;
  border-radius: 20px;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.22),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.158148),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.131144),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.11),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0888564),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.061852);
  box-sizing: border-box;
  padding: 2em 4em;

  display: flex;
  flex-direction: column;
  align-items: stretch;
}

#title {
  font-family: $primary_font_family;
  color: white;
  padding: 1%;
  margin: 10px 20%;
  background-color: $secondary_color;
  border: 2px solid $primary_color;
  border-radius: 20px;
  text-align: center;
}

.el-meollo {
  display: flex;
  align-items: flex-start;
  column-gap: 15px;
  img {
    width: 50%;
    background-color: $primary_color;
    border: 2px solid $primary_color;
    border-radius: 20px;
  }
  #setup-info {
    display: flex;
    flex-direction: column;

    text-align: center;
    padding: 10px;
    background-color: $primary_color;
    color: white;
    font-family: $primary_font_family;
    width: 100%;
    border-radius: 20px;
    border: 2px solid $secondary_color;
  }
}

@media screen and (max-width: 550px) {
  .el-meollo {
    flex-wrap: wrap;
    img {
      width: 100%;
    }
  }
}
