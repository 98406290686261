@import "../../styles/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Exo+2&family=Montserrat:wght@500;700&display=swap");

.content {
  margin: 10px;
  background-color: $secondary_color;
  border-radius: 20px;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.22),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.158148),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.131144),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.11),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0888564),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.061852);
  box-sizing: border-box;
  padding: 2em 4em;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-pic {
  width: 6em;
  height: 6em;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0px 9px 152px rgba(0, 0, 0, 0.39),
    0px 4.16097px 70.2742px rgba(0, 0, 0, 0.289253),
    0px 2.38081px 40.2093px rgba(0, 0, 0, 0.244445),
    0px 1.44514px 24.4068px rgba(0, 0, 0, 0.21058),
    0px 0.870759px 14.7061px rgba(0, 0, 0, 0.17942),
    0px 0.484895px 8.18933px rgba(0, 0, 0, 0.145555),
    0px 0.20855px 3.52218px rgba(0, 0, 0, 0.100747);
}

.username {
  font-size: 3rem;
  text-decoration: none;
  margin: 0.5em 0em 1em 0em;
  color: white;
  text-shadow: 0px 9px 152px rgba(0, 0, 0, 0.39),
    0px 4.16097px 70.2742px rgba(0, 0, 0, 0.289253),
    0px 2.38081px 40.2093px rgba(0, 0, 0, 0.244445),
    0px 1.44514px 24.4068px rgba(0, 0, 0, 0.21058),
    0px 0.870759px 14.7061px rgba(0, 0, 0, 0.17942),
    0px 0.484895px 8.18933px rgba(0, 0, 0, 0.145555),
    0px 0.20855px 3.52218px rgba(0, 0, 0, 0.100747);
  font-family: $primary_font_family;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.link-items {
  background: coral;
  padding: 0.5em;
  font-size: 1.7rem;
  text-decoration: none;
  color: white;
  border-radius: 10px;
  text-align: center;
  width: 20em;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #17e9de, #2385d5);
  background-size: 400% 400%;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.22),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.158148),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.131144),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.11);
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2em;
  font-family: $primary_font_family;
  font-weight: bold;
  &:hover {
    transform: scale(1.1);
  }
}

@media screen and (max-width: 700px) {
  .username {
    font-size: 2rem;
  }
  .link-items {
    width: 10em;
  }
  .links {
    gap: 2.5em;
  }
  .content {
    margin: 1em 0em;
    padding: 1em;
  }
}
